import utilsApi from "@/api/utilsApi";

export function sendErrorEmail(data) {
    
    // 从错误消息中提取关键信息
    let errorInfo = {};
    if (data.body.message) {
        const msg = data.body.message;
        errorInfo = {
            message: msg.message || '',
            name: msg.name || '',
            stack: msg.stack || '',
            code: msg.code || '',
            baseURL: msg.config?.baseURL || '',
            method: msg.config?.method || '',
            url: msg.config?.url || ''
        };
    }

    let sendData = {
        to: "fengyi.cai@foxmail.com",
        subject: `[WEB] Error Log Notification: ${data.subject}`,
        body: `
⏰ 时间: ${new Date().toLocaleString()}
🔰 级别: ERROR
📍 位置: ${data.body.file} - ${data.body.function}
💬 消息: ${data.subject}

📋 额外信息:
${JSON.stringify(data.body.extra)}

🚨 错误详情:
* 请求地址: ${errorInfo.baseURL}
* 请求路径: ${errorInfo.url}
* 请求方法: ${errorInfo.method}
* 错误代码: ${errorInfo.code}
* 错误类型: ${errorInfo.name} 
* 错误信息: ${errorInfo.message}
* 堆栈信息: ${errorInfo.stack}
`
    }
    return utilsApi.post('/send-email', sendData);
}


// import axios from 'axios';

// export function sendEmail(data) {
//     return axios.post('https://utils.gongyetong.cn/send-email', data);
// }